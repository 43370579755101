

export const SkillCard = (params) => {
    return(
        <div className="item">
        <svg width="212" height="212" xmlns="http://www.w3.org/2000/svg" fill="none">
          <defs>
            <linearGradient id="svg_3" x1="-0.06095" y1="0.44713" x2="1.11825" y2="0.45146">
            <stop stop-color="#1490ce" stop-opacity="0.98438" offset="0.01953"/>
            <stop stop-color="#1a688c" offset="0.35547" stop-opacity="0.99219"/>
            <stop offset="1" stop-color="#003e5c" stop-opacity="0.98828"/>
            <stop stop-color="0" stop-opacity="0" offset="NaN"/>
            <stop stop-color="0" stop-opacity="0" offset="NaN"/>
            </linearGradient>
          </defs>
            <title>Layer 1</title>
            <path d="m187.639,69.9021c5.453,-2.0435 11.587,0.7122 13.057,6.3463c5.542,21.2379 4.106,43.8026 -4.26,64.2976c-9.699,23.764 -28.001,42.997 -51.254,53.863c-23.254,10.866 -49.7487,12.566 -74.1999,4.76c-24.4512,-7.805 -45.061,-24.541 -57.7179,-46.87c-12.65687,-22.329 -16.43038,-48.609 -10.5677,-73.5974c5.86268,-24.9882 20.9305,-46.8476 42.1976,-61.2174c21.2671,-14.36981 47.17,-20.1936 72.5409,-16.30956c21.882,3.3498 41.98,13.70806 57.356,29.37196c4.078,4.1554 3.292,10.8339 -1.262,14.4624c-4.554,3.6284 -11.144,2.8182 -15.334,-1.2258c-11.983,-11.5677 -27.318,-19.219 -43.951,-21.7653c-20.1259,-3.0811 -40.6739,1.5387 -57.5446,12.9379c-16.8706,11.3992 -28.8236,28.7397 -33.4743,48.5622c-4.6507,19.822 -1.6572,40.67 8.3831,58.383c10.0404,17.713 26.3897,30.989 45.7861,37.181c19.3965,6.192 40.4147,4.843 58.8607,-3.777c18.446,-8.619 32.964,-23.876 40.659,-42.727c6.359,-15.579 7.67,-32.6663 3.901,-48.89c-1.318,-5.6716 1.372,-11.7423 6.824,-13.7859z" fill="url(#svg_3)" id="svg_1"/>
            <polygon points="580.7759065022574,494.53668647301794 472.536686473018,602.7759065022574 319.46331352698206,602.7759065022574 211.22409349774264,494.536686473018 211.22409349774264,341.46331352698206 319.46331352698195,233.2240934977427 472.536686473018,233.22409349774267 580.7759065022573,341.4633135269819" id="svg_2"/>
            <image href={params.imgSource} height="100" width="100" x="25%" y="25%"/>
          </svg>
          <h5>{params.skillName}</h5>
        </div>
    )
}